import React, { useEffect } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import { InteractionStatus } from "@azure/msal-browser";
/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
function handleLogin(instance) {
  console.log("handle login ");
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error("login error msg", e);
  });
}
 
 
 
export const PageLayout = (props) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress  } = useMsal();
  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      // console.log("authenticated");
      handleLogin(instance);
    } else {
      // handleLogin(instance);
      console.log("authenticated");  
    }
    // console.log("from use effect ");
  }, [inProgress, instance, isAuthenticated]);
 
 
 
  return (
     <>
            {
            isAuthenticated ? props.children :
            <div style={{margin: '20px 0 0 10px',fontSize: '20px',fontStyle: 'italic',fontWeight: 'bold'}}>
            Checking Authentication...
            </div>
            }
      </>
 
  );
 
};