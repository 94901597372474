import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
// import Typography from "@material-ui/core/Typography";
// import "./Home.css";
 import "./WelcomeName.css";
 
const WelcomeName = () => {
    const { instance } = useMsal();
    const [name, setName] = useState(null);
 
    const activeAccount = instance.getActiveAccount();
   
    useEffect(() => {
   
         if (activeAccount) {
            setName(activeAccount.name);
        } else {
            setName(null);
        }
    }, [activeAccount]);
 
    if (name) {
        console.log(name);
        return <div className="userName">Hello, {name} ! </div>;
    } else {
        return null;
    }
};
 
export default WelcomeName;