import React from "react";
import "./App.css";
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";
import { PageLayout } from "./Component/PageLayout";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { loginRequest } from "./authConfig";

import { Route, Switch, BrowserRouter as Router, useHistory, NavLink } from 'react-router-dom';

import { CreateEmployee } from './Component/CreateEmployee';
import { GetAllEmployeeDetails } from './Component/GetAllEmployeeDetails';
import { GetEmployeeDetailsById } from './Component/GetEmployeeDetailsById';
import { UpdateEmployee } from './Component/UpdateEmployee';
import { DeleteEmployeeDetailsById } from './Component/DeleteEmployeeDetailsById';

import WelcomeName from "./Component/WelcomeName";

function App({ pca }) {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <PageLayout>
        <div container justifyContent="center">
          <Pages />
        </div>
      </PageLayout>
    </MsalProvider>
  );
}

function Pages() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  console.log("Logged in as : " + activeAccount.idTokenClaims.name);
  useEffect(() => {
    if (activeAccount) {
      fetchAccessToken();
    }
  }, [activeAccount]);

  const fetchAccessToken = async () => {
    if (!activeAccount) {
      console.error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
      return;
    }

    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: activeAccount,
      });
      const bearer = `Bearer ${response.accessToken}`;
      localStorage.setItem("token", bearer);
    } catch (error) {
      console.error("Error fetching access token:", error);
      localStorage.clear();
    }
  };

  return (
    <>
      <div className="header"><WelcomeName /></div>
      <div className="App">
        <h2>Welcome to Employee Management System</h2>
        <nav className="horizontal-menu">
          <NavLink to="/CreateEmployee">Create Employee</NavLink>
          <NavLink to="/GetEmployee">Get All Employee Details</NavLink>
          <NavLink to="/GetEmployeeById">Get Employee by Id</NavLink>
          <NavLink to="/UpdateEmployee">Update Employee</NavLink>
          <NavLink to="/DeleteEmployee">Delete Employee</NavLink>
        </nav>
        <Switch>
          <Route exact path="/" />
          <Route path="/CreateEmployee" component={CreateEmployee} />
          <Route path="/GetEmployee" component={GetAllEmployeeDetails} />
          <Route path="/GetEmployeeById" component={GetEmployeeDetailsById} />
          <Route path="/UpdateEmployee" component={UpdateEmployee} />
          <Route path="/DeleteEmployee" component={DeleteEmployeeDetailsById} />
        </Switch>
      </div>
    </>
  );
}

export default App;
