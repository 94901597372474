import React, { useState } from 'react';
import axios from "axios";
import { NavLink } from 'react-router-dom';
import "./EmployeeForm.module.css";

export function UpdateEmployee() {
    const [employeeData, setEmployeeData] = useState({
        employeeId: '',
        employeeName: '',
        salary: '',
        departmentCode: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [updatedEmployee, setUpdatedEmployee] = useState(null);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEmployeeData({ ...employeeData, [name]: value });
    };

    const handleSubmit = () => {
        axios.put("/api/emp/controller/updateEmp", employeeData)
            .then(response => {
                setErrorMessage('');
                setUpdatedEmployee(response.data);
            })
            .catch(error => {
                setUpdatedEmployee(null);
                setErrorMessage(error.message);
            });
    };

    return (
        <div>
            <center>
                <h2>Update Employee</h2>
                <table>
                    <tbody>
                        <tr>
                            <th>Employee Id</th>
                            <td><input type="text" name="employeeId" value={employeeData.employeeId} onChange={handleChange} /></td>
                        </tr>
                        <tr>
                            <th>Employee Name</th>
                            <td><input type="text" name="employeeName" value={employeeData.employeeName} onChange={handleChange} /></td>
                        </tr>
                        <tr>
                            <th>Salary</th>
                            <td><input type="text" name="salary" value={employeeData.salary} onChange={handleChange} /></td>
                        </tr>
                        <tr>
                            <th>Department Code</th>
                            <td><input type="text" name="departmentCode" value={employeeData.departmentCode} onChange={handleChange} /></td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <button onClick={handleSubmit}>Update Employee</button> <br /><br />

                {updatedEmployee ?
                    <table>
                        <tbody>
                            <tr>
                                <th>Employee Id</th>
                                <td>{updatedEmployee.employeeId}</td>
                            </tr>
                            <tr>
                                <th>Employee Name</th>
                                <td>{updatedEmployee.employeeName}</td>
                            </tr>
                            <tr>
                                <th>Salary</th>
                                <td>{updatedEmployee.salary}</td>
                            </tr>
                            <tr>
                                <th>Department Code</th>
                                <td>{updatedEmployee.departmentCode}</td>
                            </tr>
                        </tbody>
                    </table>
                    :
                    <h4>{errorMessage}</h4>
                }
            </center>
        </div>
    );
}
