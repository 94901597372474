import React, { useState } from 'react';
import axios from "axios";
import { NavLink } from 'react-router-dom';
import "./EmployeeForm.module.css";

export function DeleteEmployeeDetailsById() {
    const [employeeId, setEmployeeId] = useState('');
    const [outputEmployeeData, setOutputEmployeeData] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = () => {
        axios.delete(`/api/emp/controller/deleteEmp/${employeeId}`)
            .then(response => {
                setErrorMessage('');
                setOutputEmployeeData(response.data);
            })
            .catch(error => {
                setOutputEmployeeData(null);
                setErrorMessage(error.message);
            });
    };

    return (
        <div>
            <center>
                <h2>Delete Employee Details By Id</h2>
                EmployeeId: <input name="employeeId" type="text" value={employeeId} onChange={(event) => setEmployeeId(event.target.value)}></input>
                <button onClick={handleSubmit}>Delete Data</button> <br /><br />

                {outputEmployeeData ?
                    <table>
                        <tbody>
                            <tr>
                                <th>Employee Id</th>
                                <td>{outputEmployeeData.employeeId}</td>
                            </tr>
                            <tr>
                                <th>Employee Name</th>
                                <td>{outputEmployeeData.employeeName}</td>
                            </tr>
                            <tr>
                                <th>Salary</th>
                                <td>{outputEmployeeData.salary}</td>
                            </tr>
                            <tr>
                                <th>Department Code</th>
                                <td>{outputEmployeeData.departmentCode}</td>
                            </tr>
                        </tbody>
                    </table>
                    :
                    <h4>{errorMessage}</h4>
                }
            </center>
        </div>
    );
}
