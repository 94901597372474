import React, { useState, useEffect } from 'react';
import axios from "axios";
import { NavLink } from 'react-router-dom';
import "./EmployeeForm.module.css";

export function GetAllEmployeeDetails() {
    const [employeeList, setEmployeeList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("/api/emp/controller/getDetails");
                setEmployeeList(response.data);
                setErrorMessage('');
            } catch (error) {
                console.error("Error fetching data:", error);
                setEmployeeList([]);
                setErrorMessage(error.message);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <center>
                <h2>Get All Employee Details</h2>
                {employeeList.length > 0 ?
                    <table>
                        <thead>
                            <tr>
                                <th>Employee Id</th>
                                <th>Employee Name</th>
                                <th>Salary</th>
                                <th>Department Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employeeList.map((emp) =>
                                <tr key={emp.employeeId}>
                                    <td>{emp.employeeId}</td>
                                    <td>{emp.employeeName}</td>
                                    <td>{emp.salary}</td>
                                    <td>{emp.departmentCode}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    :
                    <h4>{errorMessage}</h4>
                }
            </center>
        </div>
    );
}
