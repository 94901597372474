import React, { useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import './EmployeeForm.module.css';

export function GetEmployeeDetailsById() {
    const [employeeId, setEmployeeId] = useState("");
    const [employeeData, setEmployeeData] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const submitHandler = () => {
        setEmployeeData(null); // Reset previous data
        setErrorMessage(""); // Reset previous error
        axios.get(`/api/emp/controller/getDetailsById/${employeeId}`)
            .then(response => {
                setEmployeeData(response.data);
            })
            .catch(error => {
                setErrorMessage(error.message);
            });
    };

    return (
        <div>
            <center>
                <h2>Get Employee Details By Id</h2>
                EmployeeId: <input 
                    name="employeeId" 
                    type="text" 
                    value={employeeId} 
                    onChange={event => setEmployeeId(event.target.value)} 
                />
                <button onClick={submitHandler}>Get Data</button> 
                <br/><br/>
                {employeeData ? (
                    <table>
                        <tbody>
                            <tr>
                                <th>Employee Id</th>
                                <td>{employeeData.employeeId}</td>
                            </tr>
                            <tr>
                                <th>Employee Name</th>
                                <td>{employeeData.employeeName}</td>
                            </tr>
                            <tr>
                                <th>Salary</th>
                                <td>{employeeData.salary}</td>
                            </tr>
                            <tr>
                                <th>Department Code</th>
                                <td>{employeeData.departmentCode}</td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    <h4>{errorMessage}</h4>
                )}
            </center>
        </div>
    );
}
