import React, { useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import './EmployeeForm.module.css';

export function CreateEmployee() {
    const [employee, setEmployee] = useState({ employeeName: '', salary: '', departmentCode: '' });
    const [responseData, setResponseData] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const submitHandler = () => {
        axios.post("/api/emp/controller/addEmp", employee)
            .then(response => {
                setErrorMessage(""); 
                setResponseData(response.data); 
            })
            .catch(error => {
                setResponseData(null); 
                setErrorMessage(error.message); 
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmployee(prevState => ({ ...prevState, [name]: value }));
    };

    return (
        <div>
            <center>
                <h2>Create Employee</h2>
                <table>
                    <tbody>
                        <tr>
                            <th>Employee Name</th>
                            <td>
                                <input
                                    type="text"
                                    name="employeeName"
                                    value={employee.employeeName}
                                    onChange={handleChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Salary</th>
                            <td>
                                <input
                                    type="text"
                                    name="salary"
                                    value={employee.salary}
                                    onChange={handleChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>Department Code</th>
                            <td>
                                <input
                                    type="text"
                                    name="departmentCode"
                                    value={employee.departmentCode}
                                    onChange={handleChange}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <button onClick={submitHandler}>Create Employee</button>
                <br /><br />
                {/* Conditional Rendering: Display response data or error message */}
                <h4>{responseData || errorMessage}</h4>
            </center>
        </div>
    );
}
